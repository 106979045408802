import React from "react"
import sennLogo from "../img/sennLogo.svg"
import MenuNav from "./menunav";

export const Nav = () => {

    return (
        <div className="head-nav">
            
            <img src= {sennLogo} alt=""  className="logo"></img>
            <MenuNav></MenuNav>
        </div>
    )
    };

export default Nav;
