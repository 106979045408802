import React from "react";
import Contador from "./contador.jsx"



export const Form = () => {
    return(

    <div className="cont-form">
        <form id="form" className ="form" action="" method="POST">
            <h1 id="form-title">Contactanos</h1>
            <div className ="juntos">
                <div className="p1">
                    <p>Nombre</p>
                    <input name="Nombre" required></input>
                </div>
                <div className="p2">
                    <p>Apellido</p>
                    <input name="Apellido" required></input>
                </div>
            </div>

            <div className="otros">
                    <p>Email</p>
                    <input type="email" name="Email" className="email" required></input>
            </div>
            
            <div className ="juntos">
                    <div className="p1">
                        <p>Telefono (+56)</p>
                        <input type="tel" name="tel"  pattern="[0-9]+" required></input>
                    </div>
                    
                    <div className="seleccion">
                        <p>Servicio</p>
                        <label className="opts">
                            <select type="select" name="Seguros">
                                <option value="Bienes Residenciales">Bienes Residenciales</option>
                                <option value="Inmobiliarios y Comerciales">Inmobiliarios y Comerciales</option>
                                <option value="Plantas de Procesos y Centros de Cultivo">Plantas de Procesos y Centros de Cultivo</option>
                                <option value="Bienes Industriales">Bienes Industriales</option>
                                <option value="Sector energia">Sector energia</option>
                                <option value="Maquinarias y Equipos en General">Maquinarias y Equipos en General</option>
                                <option value="Tasaciones IFRS e Inventarios">Tasaciones IFRS e Inventarios</option>                  
                            </select>
                        </label>
                    </div>
            </div>



            <Contador></Contador>

            <div className="end-form">

                <button type="submit">enviar</button>
            </div>
        </form>
    </div>
    )
}




export default Form