import React from "react"
import Linecard from "./linecard";

import Card from "./card";

const HolderCards = (props) => {
    return (
    <div className="holder-card">
        
        <div id="column-box">
        <h1 id="services-title">Servicios</h1>
        <p id="services-underText">Los servicios ofrecidos consideran los siguientes rubros:</p>
        <div class="text-e"><h1 id="text-e2">Tasaciones</h1></div>
        
            {/* <div id="container-seconds-cards">
                <p id="services-underText2">Proyectos de construccion</p>
                <div id="services-proyect">
                    <Card></Card>
                    <Card></Card>
                </div>
                </div> */}
                <div id="container-firts-cards">
                
                <Linecard></Linecard>
                {/* <Linecard2></Linecard2> */}
            </div>
            
            
        </div>
    </div>
)
};

export default HolderCards;
