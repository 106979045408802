import React from "react"
import sennLogo from "../../img/sennLogo.svg"

const Footer = (props) => {
  return (
    <div>
       <footer className="pie-pagina">
        <div className="grupo-1">
            <div className="box">
                <figure>
                        <img id="logo-footer" src={sennLogo} alt=""/>  
                </figure>
            </div>
            <div className="box">
            </div>
            <div className="box">
                <h2>Email</h2>
                <div className="red-social">
                    <p>tasaciones@senn.cl</p>
                </div>
            </div>
        </div>
        <div className="grupo-2">
            <small>&copy; 2023 <b>Senn Consultores Ltda.</b> - Todos los Derechos Reservados.</small>
        </div>
    </footer>
    </div>
  )
};

export default Footer;
