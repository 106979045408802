import React from "react"


export const Cobertura = () => {
    return (

        

        <div>
            <div className ="container-all">
                <div className="container">
                    <div className="text"> 
                        <h1>Cobertura</h1>
                        <p>Si bien se trata de una firma especialista, contamos con una red de tasadores en las principales Capitales Regionales, que nos permite abordar y entregar una cobertura nacional si el requerimiento así lo amerita.</p>
                        <p>Entre las áreas de especialidad contamos con: </p>
                        <div className = "bubble-container">
                            <div className="bubble">Ing. Civiles</div>
                            <div className="bubble">Arquitectos</div>
                            <div className="bubble">Constructores</div>
                            <div className="bubble">Ing. Mecánicos</div>
                            <div className="bubble">Ing. Agrónomos</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    };

export default Cobertura;
