import React from "react"


export const Evaluacion = () => {
    return (

        <div>
            <div className ="container-all-e">
                <div className="container-e">
                    <div className="text-e"> 
                        <h1>Evaluación y Seguimiento de Proyectos</h1>
                        
                    </div>
                </div>
            </div>
        </div>
    )
    };

export default Evaluacion;
