import React, { useState, useEffect } from 'react';
import menubtn from '../img/menubtn.svg';
import xbtn from '../img/xbtn.svg';

const MenuNav = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [imagenSrc, setImagenSrc] = useState(menubtn);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    setImagenSrc(showMenu ? menubtn : xbtn); // Cambiar la imagen del botón al activar/desactivar el menú
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setShowMenu(screenWidth > 769);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <img className="menu" src={imagenSrc} alt='Botón del menú' onClick={toggleMenu} />
      {showMenu}
      <div>
        <ul className="navBar" style={{
                  display: 'flex',
                  height: showMenu ? '50vh' : '0',
                  overflow: 'hidden',
                  transition: 'all 0.5s',
                  }}>

          <li ><a href="#in" className="itemNavbar">inicio</a></li>
          <li><a href="#ser" className="itemNavbar">servicio</a></li>
          <li><a href="#cob" className="itemNavbar">cobertura</a></li>
          <li><a href="#cont" className="itemNavbar">contacto</a></li>
        </ul>
      </div>
    </div>
  );
};

export default MenuNav;

