import React from "react"
import Card from "./card";

//Icons
import sectorEnergia from "../../img/cards/sectorenergia.svg"
import residenciales from "../../img/cards/residenciales.svg"
import comerciales from "../../img/cards/comerciales.svg"
import cultivo from "../../img/cards/cultivo.svg"
import industrial from "../../img/cards/industrial.svg"
import maquinaria from "../../img/cards/maquinaria.svg"
import ifrs from "../../img/cards/ifrs.svg"

const Linecard = (props) => {
    
    
return(
    <div className="line-card">
      <Card className="tasaciones-card" type="medium" title="Bienes Residenciales" text="Casas, departamentos y parcelas de agrado" icon={residenciales}></Card>
      <Card className="tasaciones-card"  type="medium" title="Inmobiliarios y Comerciales" text="Edificios o unidades individuales." icon={comerciales}></Card>
      <Card className="tasaciones-card"  type="medium" title="Plantas de Procesos y Centros de Cultivo" text="Valoración Plantas de Proceso, Pisciculturas, Centro de Cultivos y Concesiones en general." icon={cultivo}></Card>
      <Card className="tasaciones-card" type="big" title="Bienes Industriales" text="Unidades productivas, plantas industriales, maquinarias y equipos. Estimación de costos de instalación, montaje y puesta en marcha. Se incluyen Proyectos Mineros y Garantías Especiales." icon={industrial}></Card>
      <Card className="tasaciones-card" title="Sector Energía" text="Plantas Termoeléctricas, Plantas Térmicas, Parques Fotovoltaicos y Eólicos." icon={sectorEnergia}></Card>
      <Card className="tasaciones-card" type="medium" title="Maquinarias y Equipos en General" text="Frigoríficos, Líneas de Packing, Metalmecánica, Minería, Naval y otros." icon={maquinaria}></Card>
      <Card className="tasaciones-card" type="medium" title="Tasaciones IFRS e Inventarios" text="Valoración para normas IFRS, toma y conciliación de inventarios" icon={ifrs}></Card>
    </div>
  )
};

export default Linecard;
