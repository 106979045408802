
import React, { useState, useEffect } from "react";
import sennLogo2 from "../img/sennLogo2.svg"
import b1 from "../img/backgrounds/b1.png"
import b2 from "../img/backgrounds/b2.png"
import b3 from "../img/backgrounds/b3.png"
import b4 from "../img/backgrounds/b4.png"
import b5 from "../img/backgrounds/b5.png"


export const Inicio = () => {
    
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const imageUrls = [b1,b2,b3,b4,b5];

    useEffect(() => {

        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) =>
            (prevIndex + 1) % imageUrls.length
            );
        }, 5500);
    
        return () => clearInterval(interval);
        }, [imageUrls.length]);
    
    return (
        <div className="desc-container" style={{ backgroundImage: `url(${imageUrls[currentImageIndex]})` }}>
            <div className="desc">
                <img src={sennLogo2} alt="logo-grande"></img>
                <h1>Servicios de Tasaciones y Consultoría</h1>
                <p> Senn Consultores Ltda, es una empresa constituida para presentar servicios de
                    Tasaciones y Consultoría en General. Somos especialistas en el área de valorización de
                    bienes de alta complejidad y nuestra fortaleza se basa en la trayectoria en el Sector
                    Bancario y de Ingeniería en Consulta, donde se han desarrollado sobre 20.000
                    tasaciones y participado en la aprobación de más de 80.000 tasaciones de diversos
                    tipos de bienes.
                </p>
                <div className="button-container"><a href="#cont">Contacto</a></div>
            </div>
        </div>
    )
    };

export default Inicio;
