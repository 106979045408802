import React, { useState } from "react";
import CardText from "./cardText";
import CardIcon from "./cardIcon";

const Card = ({ icon, text, title,type }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="card-father-container">
      <div
        className={isHovered ? "card-container-active" : "card-container"}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <CardIcon icon={icon}></CardIcon>
        <CardText title={title} text={text} type={type}></CardText>
      </div>
    </div>
  );
};

export default Card;
