import React from "react"

const CardIcon = ({icon}) => {
  return (
    <div>
      <img className="icon-btn" src={icon} alt="" />
    </div>
  )
};

export default CardIcon;
