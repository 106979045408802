import React, { useState } from 'react';


function Contador() {
    const maxLength = 350;
    const [text, setText] = useState('');
    const remainingChars = text.length;

    const handleChange = (event) => {
        const inputText = event.target.value;
        setText(inputText.substring(0, maxLength)); 
    };

    return (
        <div className="otros">
            <p>Mensaje</p>
            <textarea
                name="message"
                value={text}
                onChange={handleChange}
                maxLength={maxLength}
                required
            />
            <div className="cantidad"><p>{remainingChars}/350</p></div>
            </div>
    );
    }

export default Contador;