import React from "react"

const CardText = ({title, text, type}) => {
  let texto = ""
  let titulo = ""
  if(type === "big"){
    titulo = <h2 id="card-title-big">{title}</h2>
    texto = <p id="card-text-big">{text}</p>
  }else if(type ==="medium"){
    titulo = <h2 id="card-title-medium">{title}</h2>
    texto = <p id="card-text-medium">{text}</p>
  }
  else{
    titulo = <h2 id="card-title">{title}</h2>
    texto = <p id="card-text">{text}</p>
  }


  return (
    <div className="letter-container">
      {titulo}
      {texto}
    </div>
  )
};

export default CardText;
